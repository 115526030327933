@tailwind base;
@tailwind components;
@tailwind utilities;
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
.para{
  font-weight: 600;
  font-size: 20px;
}

*, html {

  scroll-behavior: smooth !important;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
.scrollbar2{
  background-color: #8f3b9d;
}
.scrollbar{
  display:none;
}
.navbar{
  background-color: black;
  color: white;
}
.navbar2{
  background-color:black;
  color: white;
}
.bc{
  background-color: rgb(238, 128, 232);
}
.signup{
  background-color: #8f3b9d;
}
@media screen and (min-width: 751px) and (max-width: 1270px) {
  /* Styles for screens between 1271px and 750px */
  .para{
    font-size: 15px;
  }
}
@media screen and (max-width: 750px) {
  .taha{
    display: none;
  }
  .taha2{
    display: block;
  }
  .para{
    font-size: 12px;
   
  }
  
}

.box-inner {
  background-color: #28012e;
  box-shadow: 2px 2px 12px 0 rgba(0,0,0,0.58);
  
  background-image: 
    url("data:image/svg+xml;charset=UTF-8,%3csvg xmlns='http://www.w3.org/2000/svg' width='1600' height='198'%3e%3cdefs%3e%3clinearGradient id='a' x1='50%25' x2='50%25' y1='-10.959%25' y2='100%25'%3e%3cstop stop-color='%238F3B9D' stop-opacity='.25' offset='0%25'/%3e%3cstop stop-color='%23683172' offset='100%25'/%3e%3c/linearGradient%3e%3c/defs%3e%3cpath fill='url(%23a)' fill-rule='evenodd' d='M.005 121C311 121 409.898-.25 811 0c400 0 500 121 789 121v77H0s.005-48 .005-77z'/%3e%3c/svg%3e"),
    url("data:image/svg+xml;charset=UTF-8,%3csvg xmlns='http://www.w3.org/2000/svg' width='1600' height='198'%3e%3cdefs%3e%3clinearGradient id='a' x1='50%25' x2='50%25' y1='-10.959%25' y2='100%25'%3e%3cstop stop-color='%23683172' stop-opacity='.25' offset='0%25'/%3e%3cstop stop-color='%238F3B9D' offset='100%25'/%3e%3c/linearGradient%3e%3c/defs%3e%3cpath fill='url(%23a)' fill-rule='evenodd' d='M.005 121C311 121 409.898-.25 811 0c400 0 500 121 789 121v77H0s.005-48 .005-77z'/%3e%3c/svg%3e"),
    url("data:image/svg+xml;charset=UTF-8,%3csvg xmlns='http://www.w3.org/2000/svg' width='1600' height='198'%3e%3cdefs%3e%3clinearGradient id='a' x1='50%25' x2='50%25' y1='-10.959%25' y2='100%25'%3e%3cstop stop-color='%23421349' stop-opacity='.25' offset='0%25'/%3e%3cstop stop-color='%23421349' offset='100%25'/%3e%3c/linearGradient%3e%3c/defs%3e%3cpath fill='url(%23a)' fill-rule='evenodd' d='M.005 121C311 121 409.898-.25 811 0c400 0 500 121 789 121v77H0s.005-48 .005-77z'/%3e%3c/svg%3e"),
    url("data:image/svg+xml;charset=UTF-8,%3csvg xmlns='http://www.w3.org/2000/svg' width='1600' height='198'%3e%3cdefs%3e%3clinearGradient id='a' x1='50%25' x2='50%25' y1='-10.959%25' y2='100%25'%3e%3cstop stop-color='%239948a8' stop-opacity='.25' offset='0%25'/%3e%3cstop stop-color='%239948a8' offset='100%25'/%3e%3c/linearGradient%3e%3c/defs%3e%3cpath fill='url(%23a)' fill-rule='evenodd' d='M.005 121C311 121 409.898-.25 811 0c400 0 500 121 789 121v77H0s.005-48 .005-77z'/%3e%3c/svg%3e");
  background-repeat: repeat-x;
  background-size: 1600px 50%;
  
  
  animation: 20s waves linear infinite forwards;
}

@keyframes waves {
  0% {background-position: 0 130%, -50px 130%, 500px 130%, 250px 130%;}
  50% {
    background-position: 1600px 130%, 3150px 130%, 5300px 130%, 250px 130%
  }
  100% {background-position: 0 130%, -50px 130%, 500px 130%, 250px 130%}
}

